import React, { Suspense, lazy } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { CpLoader } from "canopy-styleguide!sofe";
import { hasAccess, useWithUserAndTenant } from "cp-client-auth!sofe";
import { featureEnabled } from "feature-toggles!sofe";

const GlobalDashboard = lazy(() =>
  import(/* webpackChunkName: "dashboard.component" */ "./dashboard/global-dashboard.component.js")
);
const Settings = lazy(() => import(/* webpackChunkName: "gs-settings.component" */ "./settings/settings.container.js"));
const Messages = lazy(() => import(/* webpackChunkName: "gs-messages.component" */ "./messages/messages.component.js"));
import { ShowPageNotFoundComponent } from "canopy-urls!sofe";

import { ErrorBoundary } from "error-logging!sofe";

@ErrorBoundary({ featureName: "notifications center", noStrictMode: false })
export default class Root extends React.Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={<CpLoader />}>
          <Switch>
            <Route path="/notifications/dashboard/:topicId" component={GlobalDashboard} />
            <Route exact path="/notifications/dashboard" component={GlobalDashboard} />
            <Route path="/notifications/global-settings/preferences" component={Settings} />
            <PrivateRoute path="/notifications/global-settings/messages" permissions={"templates_messages"}>
              <Messages />
            </PrivateRoute>
            <Route component={ShowPageNotFoundComponent} />
          </Switch>
        </Suspense>
      </HashRouter>
    );
  }
}

function PrivateRoute({ children, permissions, ...rest }) {
  const [user] = useWithUserAndTenant();
  if (!user) return null;
  return (
    <Route
      {...rest}
      render={({ location, match, history }) =>
        hasAccess(user)(permissions) || !permissions ? (
          // cloning to provide react router props which will be used by children
          React.cloneElement(children, { location, match, history })
        ) : (
          <Redirect to="/403" />
        )
      }
    />
  );
}
